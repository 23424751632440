import React, { useState, useContext, useEffect, useCallback } from 'react'
import Autosuggest from 'react-autosuggest'
import { GiFlowers, GiTopHat } from 'react-icons/gi'
import { FaWindowClose, FaDownload } from 'react-icons/fa'
import { AiTwotoneEyeInvisible, AiFillEye } from 'react-icons/ai'
// import './ImageViewer.css'
import { Person, Relation } from '../../models/Person'
import { BlobMedia } from '../../models/BlobMedia'
import PEOPLE from '../../data/people'
import { BlobContext } from '../../contexts/BlobContext'
import { UserContext } from '../../contexts/UserContext'
import downloadFile from '../../services/fileDownload'


type OverlayState = {
  value: string
  suggestions: Person[]
}

const INITIAL_STATE: OverlayState = {
  value: '',
  suggestions: [],
}

const TAGGABLE = PEOPLE.filter(p => p.relatedTo !== Relation.PHOTOGRAPHER)

const Overlay: React.FC = () => {
  const { state, setSelected, tagUserOnImage, untagUserOnImage, changeImageTime, selectNext, updateCaption } = useContext(BlobContext)
  const { state: userState } = useContext(UserContext)
  const [value, setValue] = useState(INITIAL_STATE.value)
  const [iTime, setITime] = useState('')
  const [suggestions, setSuggestions] = useState(INITIAL_STATE.suggestions)

  const storeInputReference = (autosuggest: any) => {
    if (autosuggest && !iTime) {
      autosuggest.input.focus()
    }
  }
  
  // handle key presses
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    window.addEventListener('keydown', handleKeyPressEsc)

    // // changes image every X seconds
    // setInterval(() => {
    //   selectNext()
    // }, 15000)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
      window.removeEventListener('keydown', handleKeyPressEsc)
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    []
  )

  const handleKeyPress = useCallback(evt => {
    const { key, keyCode } = evt
    if (keyCode === 39) {
      selectNext()
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    []
  )
  const handleKeyPressEsc = useCallback(evt => {
    const { key, keyCode } = evt
    if (keyCode === 27) {
      closeWindow()
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    []
  )

  const closeWindow = () => setSelected(new BlobMedia({}))

  const getSuggestionValue = (suggestion: any) => suggestion.name

  const onChange = (event: any, { newValue }: any) => {
    setValue(newValue)
  }

  const suggestedSelected = (event: any, { suggestion }: {suggestion:Person}) => {
    if (suggestion.name) {
      tagUserOnImage(suggestion.name)
    }
    setValue('')
  }
 
  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = ({ value }: any) => {
    const _getSuggestions = (value: string) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
  
      if (inputLength === 0) return []
      return TAGGABLE.filter(p => p.name.toLowerCase().indexOf(inputValue) > -1)
    }

    setSuggestions(_getSuggestions(value))
  }
 
  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions(INITIAL_STATE.suggestions)
  }

  const renderSuggestion = (suggestion: Person) => (
    <button className="suggestion">
      {suggestion.relatedTo===Relation.BRIDE && <GiFlowers className='pink' />}
      {suggestion.relatedTo===Relation.GROOM && <GiTopHat className='blue' />}
      <span className='name'>{suggestion.name}</span>
      <span className={suggestion.relatedTo===Relation.BRIDE?'kanji pink':'kanji blue'}>{suggestion.kanji}</span>
      {suggestion.kana && <span className='kana parens'>{suggestion.kana}</span>}
    </button>
  )

  const forceDownloadFile = async() => {
    await downloadFile(state.selected.getPublicUrl())
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', state.selected.id);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  }

  const renderTagBar = () => {
    return (
      <div style={{display:'inline-flex', width:'100%', justifyContent: 'space-between'}}>
        <div className="SearchBar">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionSelected={suggestedSelected}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              placeholder: 'Tag someone',
              value: value,
              onChange: onChange,
            }}
            theme={{
              suggestionsContainer: 'search-suggestion',
              input: 'search-input-overlay',
            }}
            ref={storeInputReference}
          />
        </div>
      </div>
    )
  }

  const renderTaggedPerson = (person: string) => {
    return (
      <span className='block' key={person}
        onClick={() => {
          const check = window.confirm(`Remove ${person} from image?`)
          if (check) {
            untagUserOnImage(person)
          }
        }}
      >{person}</span>
    )
  }

  const iTimeToString = (i: number) => {
    const s = i.toString()
    const day = s.substring(6, 8)
    const hh = s.substring(8, 10)
    const mm = s.substring(10, 12)
    return `Nov ${day}, ${hh}:${mm}`
  }
  const iTimeToInt = (s: string) => {
    const day = parseInt(s.substring(4,6))
    const hh = s.substring(8, 10)
    const mm = s.substring(11, 13)
    return parseInt(`201911${day}${hh}${mm}00`)
  }

  const renderTime = () => {
    return (
      <>
        { iTime ? <>
          <input type="text"
            value={iTime}
            onChange={e => setITime(e.target.value)}
          />
          <button onClick={() => {
            changeImageTime(iTimeToInt(iTime));
            setITime('');
          }}>ok</button>
          </>
          : <span>
            Time: {state.selected.sTime}
          </span>
        }
        { (userState.admin && !iTime) && <button
          onClick={() => {setITime(iTimeToString(state.selected.iTime));window.removeEventListener('keydown', handleKeyPress);}}
        >E</button> }
      </>
    )
  }

  const renderHide = () => {
    if (state.selected.show) {
      return (
        <AiTwotoneEyeInvisible
          className="horispace" 
          onClick={() => {
            const hide = window.confirm("Hide item?")
            if (hide) {
              updateCaption('X')
              closeWindow()
            }
          }}
        />
      )
    } else {
      return (
        <AiFillEye
          className="horispace"
          onClick={() => {
            const show = window.confirm("Show item?")
            if (show) {
              updateCaption('_')
            }
          }}
        />
      )
    }
  }

  return (
    <div className={state.selected.id?'overlay open':'overlay'}>
      <div className="overlay-inner">
        <div className="overlay-header">
          <div className="mobile-row">
            {/* close */}
            <FaWindowClose onClick={closeWindow} className="close-crosshair" style={{fontSize:'2em'}} />
            <span style={{marginLeft:'.25rem'}}>By: {state.selected.by}</span>
          </div>
          <div className="mobile-row">
            {/* tags */}
            <span>Tagged: {state.selected.tags.map(p => renderTaggedPerson(p))}</span>
          </div>
          <div className="mobile-row">
            { renderTime() }
            { userState.admin && renderHide() }
            <FaDownload className='pink' style={{fontSize:'25px',marginLeft:'.25rem'}} onClick={forceDownloadFile} />
          </div>
        </div>
        {/* recently used tags */}
        { !!state.latestTags.length && <div style={{display:'inline-flex', width:'100%', justifyContent: 'flex-start', marginTop: '1rem'}}>
          <span>Recently Used:</span>
          {state.latestTags.map(tag => <button key={`latest-${tag.name}`}
            onClick={() => {tagUserOnImage(tag.name)}}
          >
            {tag.name}
          </button>)}
        </div>}
        {/* tagbar */}
        { userState.admin && renderTagBar() }
        <div style={{ height:'calc(100vh - 125px)',width:'100%',display:'flex',justifyContent:'center' }}>
          {/* image */}
          {!state.selected.isMovie && <img className={state.selected.isPortrait?'portrait':'landscape'}
            src={state.selected.getPublicUrl()} alt='' />
          }
          {/* movie */}
          {state.selected.isMovie && <video className={state.selected.isPortrait?'portrait':'landscape'}
            src={state.selected.getPublicUrl()}
            controls autoPlay
          >
          </video>
          }
        </div>
      </div>
    </div>
  )
}

export default Overlay