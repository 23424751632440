import React, { useReducer } from 'react'


const PASSCODE = 1227
const LOCAL_STORAGE_NAME = 'kanachanIsCool'
const LOCAL_STORAGE_FLAG = 'O'

// Context

type ContextPropsState = {
  admin: boolean,
}
type ContextProps = {
  state: ContextPropsState,
  tryTurnOnAdmin: (passcode: number) => void,
  checkLocalForUser: () => void,
}
const initialContextProps: ContextProps = {
  state: {
    admin: false,
  },
  tryTurnOnAdmin: () => {},
  checkLocalForUser: () => {},
}

const UserContext = React.createContext<ContextProps>(initialContextProps)


// Reducer

type Action = {
  readonly type: 'TRY_UNLOCK_ADMIN'
  readonly payload: number
}

const UserReducer = (state: ContextPropsState, action: Action) => {
  switch (action.type) {
    case 'TRY_UNLOCK_ADMIN':
      const passcode = action.payload
      const success = passcode === PASSCODE
      if (success) {
        localStorage.setItem(LOCAL_STORAGE_NAME, LOCAL_STORAGE_FLAG)
        return { ...state, admin: true };
      }
      return state
    default:
      return state;
  }
}


// Provider

const UserProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(UserReducer, initialContextProps.state)

  const tryTurnOnAdmin = (passcode: number) => {
    dispatch({ type: 'TRY_UNLOCK_ADMIN', payload: passcode })
  }

  const checkLocalForUser = () => {
    const userFlag = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (userFlag === LOCAL_STORAGE_FLAG) {
      dispatch({ type: 'TRY_UNLOCK_ADMIN', payload: PASSCODE })
    }
  }
  
  return (
    <UserContext.Provider
      value = {{
        state,
        tryTurnOnAdmin,
        checkLocalForUser,
      }}
    >
      { children }
    </UserContext.Provider>
  )
}

// Export

export { UserContext, UserProvider }