import React from 'react'
// import './Gallery.css'
import SearchBar from './SearchBar'
import TimeSlider from './TimeSlider'
import ImageViewer from './ImageViewer'
import Overlay from './Overlay'
import { BlobProvider } from '../../contexts/BlobContext'


const Gallery: React.FC = () => {
  return (
    <section className="Gallery">
      <BlobProvider>
        <SearchBar />
        <TimeSlider />
        <Overlay />
        <ImageViewer />
      </BlobProvider>
    </section>
  )
}

export default Gallery
