import React, { useReducer } from 'react'
import { Person, Relation } from '../models/Person'
import { BlobMedia } from '../models/BlobMedia'
import blobApi from '../services/gfuncBlobApi'
import PEOPLE from '../data/people'


// Context

type ContextPropsState = {
  searchTerm: Person | null,
  files: BlobMedia[],
  filtered: BlobMedia[],
  selected: BlobMedia,
  latestTags: Person[],
  timeFilter: number[],
}
type ContextProps = {
  state: ContextPropsState,
  setSearchTerm: (searchterm: Person | null) => void,
  getFiles: () => void,
  setSelected: (file: BlobMedia) => void,
  tagUserOnImage: (userName: string) => void,
  untagUserOnImage: (userName: string) => void,
  changeImageTime: (newTime: number) => void,
  selectNext: () => void,
  updateTimeFilter: (timeFilter: number[]) => void,
  updateCaption: (caption: string) => void,
}
const initialContextProps: ContextProps = {
  state: {
    searchTerm: null,
    files: [],
    filtered: [],
    selected: new BlobMedia({}),
    latestTags: [],
    timeFilter: [201911160800, 201911161800],
  },
  setSearchTerm: () => {},
  getFiles: () => {},
  setSelected: () => {},
  tagUserOnImage: () => {},
  untagUserOnImage: () => {},
  changeImageTime: () => {},
  selectNext: () => {},
  updateTimeFilter: () => {},
  updateCaption: () => {},
}

const BlobContext = React.createContext<ContextProps>(initialContextProps)


// Reducer

type Action = {
  readonly type: 'UPDATE_SEARCH_TERM'
  readonly payload: Person | null
} | {
  readonly type: 'UPDATE_FILES'
  readonly payload: BlobMedia[]
} | {
  readonly type: 'UPDATE_FILTERED'
} | {
  readonly type: 'SET_SELECTED'
  readonly payload: BlobMedia
} | {
  readonly type: 'ADD_TAG'
  readonly payload: string[]
} | {
  readonly type: 'ADD_TAG_HISTORY'
  readonly payload: Person
} | {
  readonly type: 'CHANGE_STIME'
  readonly payload: number
} | {
  readonly type: 'CHANGE_CAPTION'
  readonly payload: string
} | {
  readonly type: 'SET_TIME_FILTER'
  readonly payload: number[]
} | {
  readonly type: 'SET_NEXT'
}

const filterFilesForTime = (files: BlobMedia[], timeFilter: number[]): BlobMedia[] => {
  const timeA = timeFilter[0] >= 201911160000 ? timeFilter[0]*100 : 0
  const timeB = timeFilter[1]  < 201911162300 ? timeFilter[1]*100 : 20200000000000
  // console.log(files.length, files[0].iTime, timeA, timeB)
  return files
    .filter(f => f.iTime >= timeA && f.iTime <= timeB)  
}

const BlobReducer = (state: ContextPropsState, action: Action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH_TERM':
      return { ...state, searchTerm: action.payload };
    case 'UPDATE_FILES':
      return { ...state, files: action.payload.filter(p => p.show) }
    case 'UPDATE_FILTERED':
      if (state.files.length && state.searchTerm) {
        const person = state.searchTerm as Person
        const filteredFiles = state.files
          .filter(file => 
            person.name === 'Untagged' ? file.tags.length === 0
            : person.name === 'Movie' ? file.isMovie
            : person.relatedTo === Relation.PHOTOGRAPHER ? file.by === person.name
            : file.tags.indexOf(person.name)>-1
          )
        return { ...state, filtered: filterFilesForTime(filteredFiles, state.timeFilter) }
      }
      return { ...state, filtered: filterFilesForTime(state.files, state.timeFilter) }
    case 'SET_SELECTED':
      return { ...state, selected: action.payload }
    case 'ADD_TAG':
      let updatedSelected = state.selected
      updatedSelected.tags = action.payload
      const i = state.files.findIndex(f => f.id === state.selected.id)
      let updatedFiles = state.files
      updatedFiles[i] = updatedSelected
      return { ...state, files: updatedFiles, selected: updatedSelected }
    case 'CHANGE_STIME':
      let updatedSelected2 = state.selected
      updatedSelected2.iTime = action.payload
      updatedSelected2.updateSTime()
      const i2 = state.files.findIndex(f => f.id === state.selected.id)
      let updatedFiles2 = state.files
      updatedFiles2[i2] = updatedSelected2
      return { ...state, files: updatedFiles2, selected: updatedSelected2 }
    case 'CHANGE_CAPTION':
      let updatedSelected3 = state.selected
      updatedSelected3.caption = action.payload
      updatedSelected3.show = updatedSelected3.caption !== 'X'
      const i3 = state.files.findIndex(f => f.id === state.selected.id)
      let updatedFiles3 = state.files
      updatedFiles3[i3] = updatedSelected3
      return { ...state, files: updatedFiles3.filter(p => p.show) }
    case 'ADD_TAG_HISTORY':
      let cur = state.latestTags
      if (cur.length >= 7) cur.shift()
      if (!cur.find(c => c.name === action.payload.name)) cur.push(action.payload)
      return { ...state, latestTags: cur }
    case 'SET_TIME_FILTER':
      return { ...state, timeFilter: action.payload }
    case 'SET_NEXT':
      const id = state.filtered.findIndex(f => f.id===state.selected.id)
      const next = id > 0
        ? state.filtered[(id<state.filtered.length-1)?id+1:0]
        : state.filtered[Math.floor(Math.random() * state.filtered.length)]
      return { ...state, selected: next }
    default:
      return state;
  }
}


// Provider

const BlobProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(BlobReducer, initialContextProps.state)

  const setSearchTerm = (person: Person | null) => {
    dispatch({ type: 'UPDATE_SEARCH_TERM', payload: person })
    dispatch({ type: 'UPDATE_FILTERED' })
  }
  
  const getFiles = async () => {
    const response = await blobApi.get('kanako-getBlobData')
    const blobs = response.data.map((d: Object) => new BlobMedia(d))
    dispatch({ type: 'UPDATE_FILES', payload: blobs })
    dispatch({ type: 'UPDATE_FILTERED' })
  }

  const setSelected = async (file: BlobMedia) => {
    dispatch({ type: 'SET_SELECTED', payload: file })
  }

  const tagUserOnImage = async (userName: string) => {
    if (state.selected.tags.indexOf(userName) > -1) {
      console.log('current tags', state.selected.tags)
      console.error('Adding tag that already exists')
      return
    }
    let tags = state.selected.tags
    tags.push(userName)
    await blobApi.post('kanako-updateBlobData', {
      id: state.selected.id,
      tags: tags,
    }).then(() => console.log())
      .catch(err => console.error('kanako-updateBlobData', err))
    dispatch({ type: 'ADD_TAG', payload: tags })
    dispatch({ type: 'UPDATE_FILTERED' })
    // @ts-ignore dont need null check here
    const person: Person = PEOPLE.find(p => p.name === userName)
    dispatch({ type: 'ADD_TAG_HISTORY', payload: person })
  }

  const untagUserOnImage = async (userName: string) => {
    if (state.selected.tags.indexOf(userName) < 0) {
      console.log('current tags', state.selected.tags)
      console.error('Removing tag that doesnt exist')
      return
    }
    let tags = state.selected.tags.filter(t => t !== userName)
    await blobApi.post('kanako-updateBlobData', {
      id: state.selected.id,
      tags: tags,
    }).then(() => console.log())
      .catch(err => console.error('kanako-updateBlobData', err))
    dispatch({ type: 'ADD_TAG', payload: tags })
    dispatch({ type: 'UPDATE_FILTERED' })

    // @ts-ignore dont need null check here
    const person: Person = PEOPLE.find(p => p.name === userName)
    dispatch({ type: 'ADD_TAG_HISTORY', payload: person })
  }

  const changeImageTime = async (newTime: number) => {
    await blobApi.post('kanako-updateBlobData', {
      id: state.selected.id,
      ts: newTime,
    }).then(() => console.log())
      .catch(err => console.error('kanako-updateBlobData', err))
    dispatch({ type: 'CHANGE_STIME', payload: newTime })
    dispatch({ type: 'UPDATE_FILTERED' })
  }

  const selectNext = () => dispatch({type: 'SET_NEXT'})

  const updateTimeFilter = (timeFilter: number[]) => {
    dispatch({ type: 'SET_TIME_FILTER', payload: timeFilter })
    dispatch({ type: 'UPDATE_FILTERED' })
  }

  const updateCaption = async (caption: string) => {
    await blobApi.post('kanako-updateBlobData', {
      id: state.selected.id,
      caption: caption,
    }).then(() => console.log())
      .catch(err => console.error('kanako-updateBlobData', err))
    dispatch({ type: 'CHANGE_CAPTION', payload: caption })
    dispatch({ type: 'UPDATE_FILTERED' })
  }

  return (
    <BlobContext.Provider
      value = {{
        state,
        setSearchTerm,
        getFiles,
        setSelected,
        tagUserOnImage,
        untagUserOnImage,
        changeImageTime,
        selectNext,
        updateTimeFilter,
        updateCaption,
      }}
    >
      { children }
    </BlobContext.Provider>
  )
}

// Export

export { BlobContext, BlobProvider }