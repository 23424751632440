import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import Header from './Header/Header'
import App from "./App/App"
import Gallery from "./Gallery/Gallery"
import { UserProvider } from '../contexts/UserContext'


const Router: React.FC = () => (
  <>
    <UserProvider>
      <Header tagline="Wedding Gallery" />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Gallery} />
          <Route exact path="/app" component={App} />
          <Route component={App} />
          {/* <Route component={NotFound} /> */}
        </Switch>
      </BrowserRouter>
    </UserProvider>
  </>
)

export default Router