import React, { useState, useContext } from 'react'
import { createSliderWithTooltip, Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import { BlobContext } from '../../contexts/BlobContext'

const RangeWTooltip = createSliderWithTooltip(Range);

const TimeSlider: React.FC = () => {
  const min = 201911159900
  const max = 201911162400
  const [points, setPoints] = useState([201911160800, 201911161800])
  const { updateTimeFilter } = useContext(BlobContext)

  const dragHandler = (values: number[]) => {
    setPoints(values)
    updateTimeFilter(values)
  }

  const formatDt = (n: number): string => {
    const s = n.toString()
    const day = s.substring(6, 8)
    if (day === '15') {
      return `Before Wedding/結婚式前`
    }
    const hr = Math.min(23, parseInt(s.substring(8, 10)))
    if (hr >= 23) {
      return `After Wedding/結婚式後`
    }

    const min = Math.min(59, parseInt(s.substring(10, 12)))
    const hrStr = hr < 10 ? `0${hr}` : `${hr}`
    const minStr = min < 10 ? `0${min}` : `${min}`
    return `Nov ${day}@${hrStr}:${minStr}`
  }

  return (
    <div className="TimeSlider">
      <div style={{display:'inline-flex',justifyContent:'space-between',width:'100%'}}>
        <span>{formatDt(points[0])}</span>
        <span>Wedding Timeline / 結婚式のタイム</span>
        <span>{formatDt(points[1])}</span>
      </div>
      <RangeWTooltip min={min} max={max} defaultValue={points}
        onChange={dragHandler}
        handleStyle={{border:'solid 2px pink'}}
      />
    </div>
  )
}

export default TimeSlider