import React from 'react'
import './App.css'


const App: React.FC = () => {
  return (
    <div className="App">
      Not sure what to do with "APP"
    </div>
  )
}

export default App
