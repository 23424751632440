export enum Relation {
  BRIDE = "Kwa",
  GROOM = "Kanako",
  NONE = "",
  PHOTOGRAPHER = "PhotoBy",
}

export class Person {
  name: string
  kanji: string
  kana: string
  relatedTo: Relation

  constructor(name: string, kanji: string, kana: string, relation: Relation) {
    this.name = name
    this.kanji = kanji
    this.kana = kana
    this.relatedTo = relation
  }
}