export class BlobMedia {
  id: string = ''
  by: string = ''
  ratio: number = 1
  caption: string = ''
  likes: number = 0
  // tags: string = ''
  // comments: string = ''
  tags: string[] = []
  comments: string[] = []
  sTime: string = ''
  
  isPortrait: boolean = false
  isMovie: boolean = false
  iTime: number = 0
  show: boolean = true

  getPublicUrl  = () => {
    return `https://storage.googleapis.com/kanachan/wedding/${this.id}`
  }

  getPublicThumbnailUrl  = () => {
    const baseFileName = this.id.split('.')[0]
    return `https://storage.googleapis.com/kanachan/wedding_thumbs/${baseFileName}.jpg`
  }

  updateSTime = () => {
    const intTime = this.iTime.toString()  // ex 20191116121500
    this.sTime = `${intTime.substring(8, 10)}:${intTime.substring(10, 12)}`
  }

  constructor(o: any) {
    this.id = o['id']
    this.by = o['by']
    this.ratio = o['dim']
    this.caption = o['caption']
    this.show = this.caption !== 'X'
    this.likes = o['likes']
    this.tags = o['tags'] ? o['tags'].split('|') : []
    this.comments = o['comments'] ? o['comments'].split('|') : []
    if (o['sTime']) { 
      this.iTime = parseInt(o['sTime'])
      this.updateSTime()
    }
    
    if (this.ratio < 1) {
      this.isPortrait = true
    }

    if (this.id && this.id.split('.')[1] === 'mp4') {
      this.isMovie = true
    }
  }
}