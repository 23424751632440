import React, { useState, useContext } from 'react'
import Autosuggest from 'react-autosuggest'
import { GiFlowers, GiTopHat } from 'react-icons/gi'
import { GoDeviceCamera } from 'react-icons/go'
// import './SearchBar.css'
import { Person, Relation } from '../../models/Person'
import PEOPLE from '../../data/people'
import { BlobContext } from '../../contexts/BlobContext'


const OTHER_SEARCH_FEATURES = [
  new Person('Untagged', 'タグなし', '', Relation.NONE),
  new Person('Movie', '映画', '', Relation.NONE),
]
const SEARCHABLE = PEOPLE.concat(OTHER_SEARCH_FEATURES)

type SearchBarState = {
  value: string
  suggestions: Person[]
}

const INITIAL_STATE: SearchBarState = {
  value: '',
  suggestions: [],
}

const SearchBar: React.FC = () => {
  const { setSearchTerm } = useContext(BlobContext)
  const [value, setValue] = useState(INITIAL_STATE.value)
  const [suggestions, setSuggestions] = useState(INITIAL_STATE.suggestions)

  const getSuggestionValue = (suggestion: any) => suggestion.name

  const onChange = (event: any, { newValue }: any) => {
    setValue(newValue)
    if (newValue === '') {
      setSearchTerm(null)      
    }
  }

  const suggestedSelected = (event: any, { suggestion }: {suggestion:Person}) => {
    setSearchTerm(suggestion)
  }
 
  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = ({ value }: any) => {
    const _getSuggestions = (value: string) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
  
      if (inputLength === 0) return []
      return SEARCHABLE.filter(p => p.name.toLowerCase().indexOf(inputValue) > -1 || p.kanji.toLowerCase().indexOf(inputValue) > -1 || p.kana.toLowerCase().indexOf(inputValue) > -1)
    }

    setSuggestions(_getSuggestions(value))
  }
 
  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions(INITIAL_STATE.suggestions)
  }

  const renderSuggestion = (suggestion: Person) => (
    <button className="suggestion">
      {suggestion.relatedTo===Relation.BRIDE && <GiFlowers className='pink' />}
      {suggestion.relatedTo===Relation.GROOM && <GiTopHat className='blue' />}
      {suggestion.relatedTo===Relation.PHOTOGRAPHER && <GoDeviceCamera className='black' />}
      <span className='name'>{suggestion.name}</span>
      <span className={suggestion.relatedTo===Relation.BRIDE?'kanji pink':'kanji blue'}>{suggestion.kanji}</span>
      {/* {suggestion.kana && <span className='kana parens'>{suggestion.kana}</span>} */}
    </button>
  )

  return (
    <div className="SearchBar">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionSelected={suggestedSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: 'Search for someone.  だれか検索しましょう。',
          value: value,
          onChange: onChange,
        }}
        theme={{
          suggestionsContainer: 'search-suggestion',
          input: 'search-input',
        }}
      />
    </div>
  )
}

export default SearchBar
