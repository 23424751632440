import { Person, Relation } from '../models/Person'

const PEOPLE: Person[] = [
  new Person('Kanako Murakami'        , '村上華奈子'  , 'むらかみ かなこ'    , Relation.BRIDE),
  new Person('Akira Murakami'         , '村上彰'    , 'むらかみ あきら'    , Relation.BRIDE),
  new Person('Aiko Murakami'          , '村上あい子'  , 'むらかみ あいこ'    , Relation.BRIDE),
  new Person('Akimichi Murakami'      , '村上彰通'   , 'むらかみ あきみち'   , Relation.BRIDE),
  new Person('Noriko Murakami'        , '村上哲子'   , 'むらかみ のりこ'    , Relation.BRIDE),
  new Person('Ouga Murakami'          , '村上凰駕'   , 'むらかみ おうが'    , Relation.BRIDE),
  new Person('Ruriha Murakami'        , '村上瑠璃巴'  , 'むらかみ るりは'    , Relation.BRIDE),
  new Person('Kuuga Murakami'         , '村上琥駕'   , 'むらかみ くうが'    , Relation.BRIDE),
  new Person('Yoriko Yasuda'          , '安田頼子'   , 'よりこ　やすだ'     , Relation.BRIDE),
  new Person('Michiko Kaneko'         , '金子美智子'  , 'みちこ　かねこ'     , Relation.BRIDE),
  new Person('Atsuko Abe'             , '阿部篤子'   , 'あつこ　あべ'      , Relation.BRIDE),
  new Person('Mariko Nikkuni'         , '新國真利子'  , 'にっくに　まりこ'    , Relation.BRIDE),
  new Person('Mio Higaki'             , '桧垣美緒'   , 'ひがき　みお'      , Relation.BRIDE),
  new Person('Kumi Saitou'            , '斉藤久美'   , 'さいとう　くみ'     , Relation.BRIDE),
  new Person('Ayako Ono'              , '小野絢子'   , 'おの　あやこ'      , Relation.BRIDE),
  new Person('Masashi Miyao'          , '宮尾昌'    , 'まさし　みやお'     , Relation.BRIDE),
  new Person('Mariko Miyao'           , '宮尾真理子'  , 'まりこ　みやお'     , Relation.BRIDE),
  new Person('Daiki Miyao'            , '宮尾大暉'   , 'だいき　みやお'     , Relation.BRIDE),
  new Person('Tomoaki Miyao'          , '宮尾知明'   , 'ともあき　みやお'    , Relation.BRIDE),
  new Person('Aoka Yoshikazu'         , '佳和青鹿'   , 'よしかず あおか'    , Relation.BRIDE),
  new Person('Yuko Fuji'              , '藤井裕子'   , 'ふじい ゆうこ'     , Relation.BRIDE),
  new Person('Tomofumi Marumo'        , '丸茂朋史'   , 'まるも ともふみ'    , Relation.BRIDE),
  new Person('Akemi Ichiki'           , '市来あけみ'  , 'いちき あけみ'     , Relation.BRIDE),
  new Person('Mai Nakata'             , '仲田麻衣'   , 'なかた まい'      , Relation.BRIDE),
  new Person('Aki Saitou'             , '齋藤亜紀'   , 'さいとう あき'     , Relation.BRIDE),
  new Person('Yuko Kinoshita'         , '木下裕子'   , 'きのした ゆうこ'    , Relation.BRIDE),
  new Person('Hisanobu Higuchi'       , '樋口壽伸'   , 'ひぐち　ひさのぶ'    , Relation.GROOM),
  new Person('Masahide Fuji'          , '藤井正英'   , 'ふじい まさひで'    , Relation.GROOM),
  new Person('Yuya Nakamura'          , '中村祐也'   , 'なかむら ゆうや'    , Relation.GROOM),
  new Person('Shinichiro Komatsubara' , '小松原真一郎' , 'こまつばら しんいちろ' , Relation.GROOM),
  new Person('Miki Komatsubara'       , '小松原真みき' , 'こまつばら みき'    , Relation.GROOM),
  new Person('Kiki Ou'               , 'キキ'    , ''     , Relation.BRIDE),
  new Person('John Pai'              , 'ジョン'   , ''     , Relation.BRIDE),
  new Person('Zooey Ou Pai'          , 'ドラちゃん' , ''     , Relation.BRIDE),
  new Person('Kwa'                   , 'クワ'    , ''     , Relation.GROOM),
  new Person('Michael'               , 'マイケル ' , ''     , Relation.GROOM),
  new Person('Paula'                 , ' ポーラー' , ''     , Relation.GROOM),
  new Person('Annie'                 , 'アニー'   , ''     , Relation.GROOM),
  new Person('Freddy'                , 'フレディ'  , ''     , Relation.GROOM),
  new Person('Camila'                , 'ミラちゃん' , ''     , Relation.GROOM),
  new Person('Albert Shin'           , 'アルベルト' , ''     , Relation.GROOM),
  new Person('Jason Le'              , 'ジェソン'  , ''     , Relation.GROOM),
  new Person('Miguel Le Rouzic'      , 'ニナ'    , ''     , Relation.GROOM),
  new Person('Nina Le Rouzic'        , 'ミゲル'   , ''     , Relation.GROOM),
  new Person('Sangmin Yi'            , 'サンミン'  , ''     , Relation.GROOM),
  new Person('John Imamura'          , 'ジョン'   , ''     , Relation.GROOM),
  new Person('Gentaro Kadowaki'      , '源太郎'   , 'げんたろ' , Relation.GROOM),

  new Person('Ukai Chikutei', 'うかい竹亭', '', Relation.NONE),
  new Person('Kusakari', '草刈さん', '', Relation.NONE),
  new Person('Friends & Family', 'みんあ', '', Relation.NONE),

  new Person('Aki', '', '', Relation.PHOTOGRAPHER),
  new Person('Albert', '', '', Relation.PHOTOGRAPHER),
  new Person('Annie', '', '', Relation.PHOTOGRAPHER),
  new Person('John Pai', '', '', Relation.PHOTOGRAPHER),
  new Person('Kanako', '', '', Relation.PHOTOGRAPHER),
  new Person('Kiki', '', '', Relation.PHOTOGRAPHER),
  new Person('Komatsubara', '', '', Relation.PHOTOGRAPHER),
  new Person('kwa', '', '', Relation.PHOTOGRAPHER),
  new Person('Mom', '', '', Relation.PHOTOGRAPHER),
  new Person('Nina', '', '', Relation.PHOTOGRAPHER),
  new Person('Official Photographer', '', '', Relation.PHOTOGRAPHER),
  new Person('Atsuko', '', '', Relation.PHOTOGRAPHER),
  new Person('Ayako', '', '', Relation.PHOTOGRAPHER),
  new Person('Gentaro', '', '', Relation.PHOTOGRAPHER),
  new Person('Jason', '', '', Relation.PHOTOGRAPHER),
  new Person('Mariko', '', '', Relation.PHOTOGRAPHER),
  new Person('Mio', '', '', Relation.PHOTOGRAPHER),
  new Person('Yoriko', '', '', Relation.PHOTOGRAPHER),
  new Person('Akimichi', '', '', Relation.PHOTOGRAPHER),
]

export default PEOPLE