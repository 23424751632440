import React, { useContext, useState, useEffect } from 'react'
import './Header.css'
import { UserContext } from '../../contexts/UserContext'
import { GiVanillaFlower, GiVineFlower, GiCarnyx } from 'react-icons/gi'


type HeaderProps = {
  tagline: string,
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const [ passCodeMode, setPassCodeMode ] = useState(false)
  const [ value, setValue ] = useState('')
  const { state, tryTurnOnAdmin, checkLocalForUser } = useContext(UserContext)

  useEffect(() => {
    checkLocalForUser()
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    []
  )

  const renderAuth = () => {
    return (
      <span className="top-right">
        <GiVineFlower />
      </span>
    )
  }
  
  const renderUnauth = () => {
    return (
      <span className="top-right">
        { passCodeMode ? <span>
            <input type="password" value={value} onChange={e => setValue(e.target.value)} />
            <GiCarnyx onClick={() => {tryTurnOnAdmin(parseInt(value));setPassCodeMode(false)}} />
          </span>
          : <GiCarnyx onClick={()=>{setPassCodeMode(true)}} />
        }
      </span>
    )
  }

  return (
    <header className="top">
      <h1>
        Kwa
        <span className="and">&</span>
        Kanako
      </h1>
      <h3 className="tagline">
        <GiVanillaFlower />
        <span>{props.tagline}</span>
        <GiVineFlower />
      </h3>
      { state.admin ? renderAuth() : renderUnauth() }
    </header>
  )
}

export default Header