import React, { useContext, useEffect } from 'react'
// import './ImageViewer.css'
import { BlobContext } from '../../contexts/BlobContext'


const ImageViewer: React.FC = () => {
  const { state, getFiles, setSelected } = useContext(BlobContext)
  
  // initialize list of files
  useEffect(() => {
    getFiles();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    []
  );

  return (
    <div className="ImageViewer">
      <div className="img-info grey">
        {state.filtered.length} Images/Videos
      </div>
      <div className="img-container">
        {state.files.length && state.filtered.map(blob => 
          <div className={blob.isPortrait?'img-item portrait':'img-item'} key={blob.id}>
            <span className="tag-count">
            <span role="img" aria-label="tag">🏷️</span>{ blob.tags.length } 
              { blob.isMovie ? '🎥' : '📷' }
              <span className='pink'>{ blob.by }</span>
            </span>
            <img alt=""
              src={blob.getPublicThumbnailUrl()}
              onClick={() => setSelected(blob)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ImageViewer